import $ from 'jquery';
import 'datatables.net-dt';
import * as DataTables from 'datatables.net-dt';

/**
 * Helper for functions relating to DataTables
 */
export const DataTablesHelper = {

    /**
    * Convert each table on the page to a DataTable
    */
    InitializeTables: function () : DataTables.Api<HTMLElement>[] {
        const initializedTables: DataTables.Api<HTMLElement>[] = [];

        // Initialize only tables that are for the currently selected language
        const urlParams = new URLSearchParams(window.location.search)
        const languageCode = urlParams.get("lang") || "en";
        let tablesToInit = $(`[data-langoption='${languageCode}'] table`);

        // If there are no tables that include data-langoption (i.e. on the School Detail page)
        if(tablesToInit.length === 0) {
            tablesToInit = $('table.dataTable');
        }

        tablesToInit.each(function () {
            if ($(this).find('thead').length === 0)
            {
                const success = DataTablesHelper.TryAddThead(this);
                if(!success) {
                    $(this).addClass('non-standard-table');
                    return;
                }
            }
            DataTablesHelper.WidenBrokenCells(this);
            const dataTable = $(this).DataTable<HTMLElement>({
                paging: false,
                searching: false,
                info: false,
                autoWidth: false,
                scrollX: true,
                ordering: false,
            });
            initializedTables.push(dataTable);
        });
        DataTablesHelper.AdjustColumns();
        
        return initializedTables;
    },

    /**
    * Revert DataTables application on all tables
    */
    RemoveTables: function (tableList: DataTables.Api<HTMLElement>[]) {
        tableList.forEach((table: DataTables.Api<HTMLElement>) => {
            table.destroy();
        });

        $('table[id*="DataTable"]').each(function() {
            this.removeAttribute('id');
        })
    },

    /**
    * Increase cell width so that scrolling gets enabled if the text has broken to more than [linesBeforeBreak = 3] lines
    */
    WidenBrokenCells: function (table: HTMLElement) {
        const tableColCount = table.querySelectorAll('thead th').length;
        const topTableRowTds = table.querySelector('tbody tr')?.querySelectorAll('td');

        // Make cell width proportional to the column count of the table
        const cellWidth = 800 / (tableColCount / 2);

        // Make each cell the same width before calculating new widths
        topTableRowTds?.forEach((td: HTMLElement) => {
            const cellContents = td.firstElementChild as HTMLElement;
                if (cellContents) {
                    cellContents.style.width = `${window.screen.width * 2.5/tableColCount}px`
                }
        });

        for(let columnIndex = 0; columnIndex < tableColCount; columnIndex++){
            const tdList = table.querySelectorAll<HTMLElement>(`tbody td:nth-child(${columnIndex + 1})`);

            tdList.forEach((td: HTMLElement) => {
                const cellContents = td.firstElementChild;
                if (cellContents) {
                    const { lineHeight } = getComputedStyle(cellContents);
                    const lineHeightParsed = lineHeight != "normal" ? parseInt(lineHeight.split('px')[0]) : 24;
                    const linesBeforeBreak = 3;
    
                    if (cellContents.clientHeight >= (lineHeightParsed * linesBeforeBreak)) {
                        td.style.minWidth = `${cellWidth}px`;
                    }
                }
                else {
                    // If the table has no first child, then it must be a table on the school details page that is 
                    // generated by the JSON in Data. 960px is the max width of the content, so divide the cells evenly
                    td.style.minWidth = `${960/tableColCount}px`;
                }
            });

            tdList.forEach((td: HTMLElement) => {
                const cellContents = td.firstElementChild;
                if (cellContents) {
                    const cellContentsAsHTMLElement = cellContents as HTMLElement;
                    // Reset width of content
                    cellContentsAsHTMLElement.removeAttribute('style');
                }
            });
        }
    },

    /**
    * Tries to add a <thead> to a table that is missing one
    */
    TryAddThead: function(table: HTMLElement) : boolean {
        const cells = table.querySelectorAll('table td, table th');
        for (let i = 0; i < cells.length; i++) {
            const cell = cells[i];
            const rowspan = cell.getAttribute('rowspan');
            const colspan = cell.getAttribute('colspan');
          
            if (rowspan && parseInt(rowspan) > 1) {
              return false;
            }
          
            if (colspan && parseInt(colspan) > 1) {
              return false;
            }
        }

        const firstRow = table.querySelector('tbody tr');
        const thead = document.createElement('thead');
        thead.appendChild(firstRow);
        table.insertBefore(thead, table.querySelector('tbody'));
        return true;
    },

    /**
    * Auto adjusts column widths of dataTables.
    */
    AdjustColumns: function() {
        // Timeout is used so that webkit (and other browsers) can load.
        if(window.InitializedTables) {
            setTimeout(function() {
                for (const table of window.InitializedTables) {
                    table.columns.adjust();
                }
            }, 50);
        }
    }
}