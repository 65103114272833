/// <reference types="../types/index.d.ts" />

import * as $ from "jquery";
import * as L from "leaflet";
import 'leaflet.markercluster';
import { MobileNavigation } from '../components/navigation';
import { DataTablesHelper } from "../components/datatables";
import { Accordion } from "../components/accordion";
import { addResizeEventListener } from "../lib/event-listeners";

// Expose libraries globally on the window
window.$ = $;
window.L = L;

window.addEventListener("load", () => {
    const mobileNavRoot = document.querySelector(".mobile-menu");
    const mobileNavigation = new MobileNavigation(mobileNavRoot);

    window.InitializedTables = DataTablesHelper.InitializeTables();

    const allAccordions = document.querySelectorAll(".accordion");
    allAccordions.forEach(accordionRoot => {
        _ = new Accordion(accordionRoot);
    });
});

addResizeEventListener(() => {
    $('table').each(function () {
        DataTablesHelper.AdjustColumns();
    });
});
