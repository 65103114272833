

/**
 * Add a resize event listener to the window with a debounce to prevent unneeded function calls
 * 
 * @param callback The function to run after the window has been resized
 */
export function addResizeEventListener(callback: Function) {
    /** The amount of time to wait in MS before executing the callback */
    const debounceDelayMs = 200;

    /** The current timeout ID*/
    let resizeEndTimeout = 0;

    window.addEventListener("resize", () => {
        if (resizeEndTimeout) {
            // If the resize event is triggered again within the debounce period, cancel the previously scheduled callback to prevent it being called multiple times
            clearTimeout(resizeEndTimeout);
        }

        // Wait until the debounce period has elapsed before executing callback function
        resizeEndTimeout = setTimeout(callback, debounceDelayMs);
    });
}
