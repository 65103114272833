/**
 * Collapsible navigation used for mobile devices
 */
export class MobileNavigation {

    private domNodes: {
        /** The root node wrapping the entire mobile navigation */
        rootNode?: HTMLElement,
        /** The whole toggle button */
        toggleButton?: HTMLElement,
        /** Toggle button icon */
        toggleButtonIcon?: HTMLElement | null,
        /** List of links in the navigation */
        links?: HTMLElement | null
    } = {}

    private classes = {
        /** Class name to apply to the root node when the menu is collapsed */
        collapsed: "mobile-menu--collapsed",
    }

    constructor(rootNode: HTMLElement) {
        // If the toggle button is not on the page, do not render the mobile navigation
        const toggleButton = rootNode.querySelector<HTMLElement>(".mobile-menu__toggle-button");
        if (!toggleButton) {
            console.warn("Could not find toggle button for mobile navigation", { rootNode })
            return;
        }

        this.domNodes = {
            rootNode: rootNode,
            toggleButton: toggleButton,
            toggleButtonIcon: rootNode.querySelector<HTMLElement>(".mobile-menu__toggle-button > .material-icons"),
            links: rootNode.querySelector<HTMLElement>(".mobile-menu__links")
        };

        // Add event listeners
        this.addEventListeners();

        // Update initial state to match markup
        if (this.isMenuCollapsed()) {
            this.hideMenu();
        }
    }

    /** Add event listeners required for the menu */
    private addEventListeners() {
        this.domNodes.toggleButton?.addEventListener("click", () => {
            if (this.isMenuCollapsed()) {
                this.showMenu();
            } else {
                this.hideMenu();
            }
        });
    }

    private showMenu() {
        this.domNodes.rootNode?.classList.remove(this.classes.collapsed);
        if (this.domNodes.toggleButtonIcon) {
            this.domNodes.toggleButtonIcon.innerText = "close"
        }
    }

    private hideMenu() {
        this.domNodes.rootNode?.classList.add(this.classes.collapsed);
        if (this.domNodes.toggleButtonIcon) {
            this.domNodes.toggleButtonIcon.innerText = "menu"
        }
    }

    private isMenuCollapsed() {
        return this.domNodes.rootNode?.classList?.contains(this.classes.collapsed);
    }

}
