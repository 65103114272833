import $ from "jquery";

export class Accordion {

    dom: {
        root: HTMLElement,
        button: HTMLButtonElement,
        content: HTMLElement,
        expandIcon: HTMLElement
    }

    isOpen: boolean = false;

    constructor(root: HTMLElement) {
        this.dom = {
            root: root,
            button: root.querySelector<HTMLButtonElement>(".accordion__title")!,
            content: root.querySelector<HTMLElement>(".accordion__content")!,
            expandIcon: root.querySelector<HTMLElement>(".accordion__expand-icon")!
        }

        if (!this.dom.button) {
            console.error("Could not find button for accordion", { domNodes: this.dom })
            return;
        }
        if (!this.dom.content) {
            console.error("Could not find content for accordion", { domNodes: this.dom })
            return;
        }
        if (!this.dom.expandIcon) {
            console.error("Could not find expand icon for accordion", { domNodes: this.dom })
            return;
        }

        this.dom.button?.addEventListener("click", (event) => {
            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });

        if(root.querySelector<HTMLElement>(".default-open")) {
            this.open();
        } else {
            this.instantClose();
        }
    }

    /** Close the accorion without any animation */
    private instantClose(){
        this.isOpen = false;
        $(this.dom?.content).hide()
        this.dom.button?.setAttribute("aria-expanded", "false");
        this.dom.expandIcon.innerText = "add";
    }

    close = () => {
        this.isOpen = false;
        $(this.dom?.content).slideUp()
        this.dom.button?.setAttribute("aria-expanded", "false");
        this.dom.expandIcon.innerText = "add";
    }

    open = () => {
        this.isOpen = true;
        $(this.dom.content).slideDown()
        this.dom.button?.setAttribute("aria-expanded", "true");
        this.dom.expandIcon.innerText = "horizontal_rule";
    }
}
